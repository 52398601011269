import React, { useState } from "react";
import "./style.scss";
import { FAQ_LIST } from "../../constants";
import {ReactComponent as RightArrow} from "../../assets/right-arrow.svg";

const Faq = () => {
  const [selected, setSelected] = useState([]);

  const handleClick = (index) => {
    const selectedClone = [...selected];
    if (selected?.includes(index)) {
      const whichIndex = selected?.indexOf(index);
      selectedClone?.splice(whichIndex, 1);
    } else {
      selectedClone?.push(index);
    }
    setSelected([...selectedClone]);
  };

  return (
    <section className="faq_wrapper">
      <h2 className="common_heading">Frequently Asked Questions</h2>
      <span class="divied"></span>
      <div className="faq_body">
        {FAQ_LIST?.map((item, index) => {
          return (
            <div key={`faq-list-${index}`} className="faq_list">
              <p
                className={`question ${
                  selected?.includes(index) ? "show_line" : "hide_line"
                }`}
                onClick={() => handleClick(index)}
              >
                {item?.question}<RightArrow className="right-arrow" />
              </p>
              <p
                className={`answer ${
                  selected?.includes(index) ? "show" : "hide"
                }`}
              >
                {item?.answer}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Faq;

