import React, { useState } from "react";
import "./style.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { POST } from "../services/APIService";
import GTMService from "../services/GTMService";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Button } from "@mui/material";
import { getLocalStorage } from "../utils";
import { EmailSend } from "./SendEmail";

const Form = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(getLocalStorage("name") || "");
  const [email, setEmail] = useState(getLocalStorage("email") || "");
  const [mobileNumber, setMobileNumber] = useState(
    getLocalStorage("mobileNumber") || ""
  );
  const [time, setTime] = useState(getLocalStorage("time") || "");
  const [message, setMessage] = useState(getLocalStorage("message") || "");

  const handleSubmit = async () => {
    const params = {
      name: name,
      number: mobileNumber,
      email: email,
      time: time,
      message: message,
      property: "Eldeco La Vida Bella",
      terms: true
    };
    try {
      const res = EmailSend(params);
      POST(
        "https://o4nzozyh09.execute-api.ap-south-1.amazonaws.com/googleWebhooks?pid=66fbe344e96d0d0bebe55745&clid=66fbe2b5a0e11f7f8c5d87ba",
        {
          name,
          phone: mobileNumber,
          email,
          message,
          "Page URL": window.location.href,
          property: "Eldeco La Vida Bella",
          time,
        }
      )
        .then((response) => {
          // console.log("response: ", response);
          GTMService.triggerEvent("submit_lead_form", {
            name,
            phone: mobileNumber,
            email,
            message,
            "Page URL": window.location.href,
            property: "Eldeco La Vida Bella",
            time,
          });
        })
        .catch((err) => {
          console.debug("err: ", err);
        });
      setShow(false);
      delete localStorage.name;
      delete localStorage.mobileNumber;
      delete localStorage.time;
      delete localStorage.email;
      delete localStorage.message;
      setName("");
      setMessage("");
      setTime("");
      setEmail("");
      setMobileNumber("");
    } catch (err) {}
  };

  const checkDisabled = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !(
      name?.length > 0 &&
      mobileNumber?.length > 8 &&
      mobileNumber?.length < 15 &&
      emailRegex.test(email)
    );
  };

  return (
    <div className="">
      {show && (
        <div
          className="with_overlay"
          onClick={() => {
            setShow(false);
          }}
        ></div>
      )}
      <div className={`form_wrapper ${show ? "show_form" : "hide_form"}`}>
        <button
          type="button"
          className="enquire_button"
          onClick={() => setShow(!show)}
        >
          Enquire Now
        </button>
        <div className="toggle_form">
          <div className="heading_wrapper">
            <h3>Leave Your Details Here</h3>
            <p>Assured Call Back Within 5 Minutes</p>
          </div>
          <div className="toggle_form_body_wrapper">
            <input
              placeholder="Your Name"
              value={name}
              onChange={(e) => {
                // setLocalStorage("name", e.target.value);
                setName(e.target.value);
              }}
            />
            <input
              placeholder="Email ID"
              value={email}
              onChange={(e) => {
                // setLocalStorage("email", e.target.value);
                setEmail(e.target.value);
              }}
            />
            <PhoneInput
              placeholder="Phone"
              value={mobileNumber}
              defaultCountry="IN"
              displayInitialValueAsLocalNumber
              onChange={(e) => {
                // setLocalStorage("mobileNumber", e);
                setMobileNumber(e);
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }} className="dropdown">
              <InputLabel id="demo-simple-select-helper-label">
                Best Time To Call You
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                className="dropdown_inside"
                value={time}
                label="Best Time To Call You"
                onChange={(e) => {
                  //   setLocalStorage("time", e.target.value);
                  setTime(e.target.value);
                }}
              >
                <MenuItem value="Within 15 Minutes">Within 15 Minutes</MenuItem>
                <MenuItem value="08:00 AM To 12:00 PM">
                  08:00 AM To 12:00 PM
                </MenuItem>
                <MenuItem value="12:00 PM To 04:00 PM">
                  12:00 PM To 04:00 PM
                </MenuItem>
                <MenuItem value="04:00 PM To 08:00 PM">
                  04:00 PM To 08:00 PM
                </MenuItem>
                <MenuItem value="AnyTime">AnyTime</MenuItem>
              </Select>
              {/* <FormHelperText>With label + helper text</FormHelperText> */}
            </FormControl>
            <textarea
              value={message}
              rows="4"
              placeholder="Message"
              cols="50"
              onChange={(e) => {
                // setLocalStorage("message", e.target.value);
                setMessage(e.target.value);
              }}
            />
            <Button autoFocus className="home_button small_btn modal_btn" onClick={handleSubmit} disabled={checkDisabled()}>
              Schedule Appointment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

