import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer_wrapper">
      <div class="container">
        {/* <div class="row">
          -<div class="col-lg-12">
            <p class="text-center">Copyright © 2021 <strong>Gulshan Dynasty</strong> | RERA APPROVED: <strong>UPRERAPJ950870</strong> </p>
          </div>
          <div class="col-lg-4 col-md-6">
            <h3>Contact Us</h3>
            <ul class="footer-address-list">
              <li><i class="fas fa-map-marker-alt"></i>Gulshan Dynasty - Apex Group</li>
        <li><i class="fas fa-map-marker-alt"></i>GH-14A Sector-1, Indirapuram, Ghaziabad</li>
        <li><i class="fas fa-map-marker-alt"></i>Greater Ghaziabad, Uttar Pradesh 201306</li>
              <li><i class="fas fa-phone-alt"></i><a href="tel:+91-91-0000000000
 ">+91-91-0000000000
 </a></li>
              <li><i class="fas fa-envelope"></i><a href="mailto:sales@acegroupdivino.com">sales@acegroupdivino.com</a></li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6">
            <h3>Follow Us</h3>
            <div class="footer-social-media-icons">
        <a href="javascript:void(0)" class="facebook"><i class="fab fa-facebook"></i></a>
        <a href="javascript:void(0)" class="twitter"><i class="fab fa-twitter"></i></a>
              <a href="javascript:void(0)" class="instagram"><i class="fab fa-instagram"></i></a>             
              <a href="javascript:void(0)" class="linkedin"><i class="fab fa-linkedin"></i></a>
            </div>
        </div>
          </div> */}
      </div>

      <div class="copyright">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="footer-">
                <p class="text-center">
                  Copyright © 2024 <strong>Eldeco la vida bella</strong> | www.up-rera.in |   
                  RERA No. : UPRERAPRJ136219
                </p>
                <p>
                  Disclaimer: © 2024 This is not the official website of the
                  developer, it belongs to authorised channel partner, and is
                  used for information &amp; marketing purposes only. All rights
                  for logo &amp; images are reserved by the developer. By using
                  or accessing this website you agree with the disclaimer
                  without any qualification or limitation. By accessing this
                  website, the viewer confirms that the information including
                  brochures and marketing collaterals on this website are solely
                  for informational purposes only and the viewer has not relied
                  on this information for making any booking/purchase in any
                  project of the company{" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
