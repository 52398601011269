import React from "react";
import HomeImg from "../assets/home/home.webp";
import "./style.scss";
import { HOME_HEADING, HOME_INFORMATION, HOME_SECONDARY_HEADING } from "../constants";
import CustomizedDialogs from "./Dialogbox";

const Home = (props) => {
  return (
    // <div>This will be image</div>
    <div className="homewrapper_main">
      <div className="home_wrapper">
        <img className="home_img" src={HomeImg} alt="Home" />
      </div>
      <div className="home_content">
        <div className="heading_wrapper">
          <h3 className="primary_heading">{HOME_HEADING}</h3>
          <span className="secondary_heading">{HOME_SECONDARY_HEADING}</span>
        </div>
        <div className="home_secondary_content">
          <div className="info_wrapper">
            {HOME_INFORMATION.map((item) => {
              return (
                <div className="info">
                  <p className="info_heading">{item?.heading}</p>
                  <p className="info_value">{item?.value}</p>
                </div>
              );
            })}
          </div>

          {/* <button className="home_button">Schedule Appointment</button> */}
          <CustomizedDialogs buttonName="Schedule Appointment" modalTitle="Schedule Appointment - Leave Your Details Here"/>
        </div>
      </div>
    </div>
  );
};

export default Home;
