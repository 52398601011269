import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

export const EmailSend = (params) => {
    emailjs
      .send("service_ajrkxqq", "template_lrfsjxz", params, "XTAVeqzLYz76Kmu-7")
      .then(
        (result) => {
          // console.log(result.text);
          toast.success("Enquiry Sent");
        },
        (error) => {
          console.log(error.text, "test error");
          toast("Wow so easy!");
        }
      );
  };

