import React from "react";
// import { useState } from "react";
import "./style.scss";
import { WELCOME_PAGE } from "../../constants";
import Wel1Img from "../../assets/Gallery/LVB_Club.jpg";
import Wel2Img from "../../assets/Gallery/LVB_Look.jpg";
import Wel3Img from "../../assets/Gallery/LVB_Look1.jpg";
import Wel4Img from "../../assets/Gallery/LVB_Park.jpg";
import Wel5Img from "../../assets/Gallery/LVB_Tower_Entry.jpg";
import CustomizedDialogs from "../Dialogbox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Welcome() {
  const { heading, content } = WELCOME_PAGE;

  const IMAGES_FOR_CAROUSEL = [

    { img: Wel1Img },
    { img: Wel2Img },
    { img: Wel3Img },
    { img: Wel4Img },
    { img: Wel5Img },

  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section class="welcome_page" id="Welcome">
      <div class="container">
        <div class="welcome_left">
          <h2 className="common_heading">{heading}</h2>
          <span class="divied"></span>
          {content.map((item) => {
            return <p>{item}</p>;
          })}
          <CustomizedDialogs
            buttonName="Download Brochure"
            modalTitle="Download Brochure Details"
          />
        </div>
        <div className="welcome_right">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            // ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={1500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {IMAGES_FOR_CAROUSEL.map((image) => {
              return <img src={image.img} />;
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Welcome;

