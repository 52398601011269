import React from "react";
// import { useState } from "react";
import "./style.scss";
import { PLAN_PAGE } from "../../constants";
import CustomizedDialogs from "../Dialogbox";

function Plan() {
  const { master, floor } = PLAN_PAGE;

  return (
    <section className="all_plan" id="Floor_Plan">
      <div className="container d_flex">
        <div className="left">
          <h2 className="common_heading">{master.heading}</h2>
          <span className="divied"></span>
          <div className="text_center">
            <img src={master.image} className="img-fluid" alt="Master Plan" />
            <CustomizedDialogs
              buttonName="View Master Plan"
              modalTitle="Floor Plan Details"
            />
          </div>
        </div>
        <div className="right">
          <h2 className="common_heading">{floor.heading}</h2>
          <span className="divied"></span>
          <div className="d_flex plan">
            {floor.image.map((item) => {
              return (
                <div className="text_center">
                  <img src={item} className="img-fluid" alt="Floor Plan" />
                  <CustomizedDialogs
                    buttonName="View Floor Plan"
                    modalTitle="Floor Plan Details"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Plan;
