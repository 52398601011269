import CustomizedDialogs from "../Dialogbox";
import "./style.scss"

function Table({ heading, data }) {
  const returnTableData = (dataItem, headingKey) => {
    if (headingKey === "action") {
      return (
        <CustomizedDialogs
          buttonName="Enquire Now"
          modalTitle="Leave Your Details Here"
        />
      );
    } else {
      return <span>{dataItem[headingKey]}</span>;
    }
  };
  return (
    <table>
      <thead>
        <tr>
          {heading.map((item) => {
            return <th>{item.value}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <tr>
              {heading.map((headingItem) => {
                return <td>{returnTableData(item, headingItem.key)}</td>;
              })}
            </tr>
          );
        })}
        <tr></tr>
      </tbody>
    </table>
  );
}

export default Table;
