export const POST = (path, data) => {
    return new Promise((resolve, reject) => {
      fetch(path, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then(resolve)
        .catch(reject);
    });
  };
  