import React from "react";
// import { useState } from "react";
import "./style.scss";
import { WHY_CHOOSE_US } from "../../constants";

function WhyChooseUs() {
  const { heading, content, boxes } = WHY_CHOOSE_US;

  return (
    <section class="whychoseWarp" id="Highlights">
      <div class="container">
        <div class="main">
          <h2 className="common_heading">{heading}</h2>
          <span class="divied"></span>
          <p className="common_content">{content}</p>
        </div>
        <div class="box_wrapper">
          {boxes.map((item) => {
            return (
              <div class="WhychoseBIx">
                <h4 className="common_secondary_heading">{item.heading}</h4>
                <p className="common_content">{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
