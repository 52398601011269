import React, { useState } from "react";
import "./style.scss";
import { MENU_ITEMS } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/home/17083390651898117943.png";
import {ReactComponent as WhatsApp} from "../assets/whatsapp.svg";

const Navbar = () => {
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const HandleScrollOnClick = (data) => {
    if (pathname !== "/") {
      navigate("/");
    }
    if (data?.class) {
      setTimeout(() => {
        const element = document?.getElementsByClassName(data?.class)[0];
        element?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 500);
    }
  };

  return (
    <div className="navbar-wrapper">
      <div className="nav-left">
        <img src={Logo} className="nav-logo" />
      </div>
      <div className={`nav-right nav ${active ? "active" : ""}`}>
        {MENU_ITEMS?.map((item) => {
          return (
            <span key={item?.id} onClick={() => HandleScrollOnClick(item)}>
              {item?.label}
            </span>
          );
        })}
        <span className="whatsapp-logo-wrapper"
          onClick={() => {
            window.open(
              "https://wa.me/7834818630/?text=Hi. I am interested in Eldeco La Vida Bella. Please contact me. My name is ",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          <WhatsApp className="whatsapp-logo" />
        </span>
      </div>
      <span className={`drawer-open mobile ${active ? "active" : ""}`}>
        <span aria-hidden onClick={() => setActive(!active)}></span>
      </span>
    </div>
  );
};

export default Navbar;

