const triggerEvent = (eventName, data) => {
    if(window && window?.dataLayer){
    window.dataLayer = window.dataLayer || [];
    }

    if (typeof window.dataLayer !== "undefined") {
      if (data && Object.keys(data).length) {
        window.dataLayer.push({
          event: eventName,
          ...data,
        });
      } else {
        window.dataLayer.push({
          event: eventName,
        });
      }
    }
  };
  
  export default {
    triggerEvent,
  };
  