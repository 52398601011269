import React from "react";
// import { useState } from "react";
import "./style.scss";
import { PRICE_PAGE } from "../../constants";
import Table from "../Table";

function Price() {
  const { heading, tableHeader, tableData } = PRICE_PAGE;

  return <section class="Price_warpper bg-grey ptb-80" id="Price">
  <div class="container">
      <div class="row">
          <div class="col-md-12 text-center">
              <h2 className="common_heading">{heading}</h2>
              <span class="divied"></span>
              <div class="price_table">
                  <div class="table-responsive">
                      <Table heading={tableHeader} data={tableData} />
                  </div>

              </div>
          </div>
      </div>
  </div>
</section>;
}

export default Price;
