import "./App.scss";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Form from "./components/form";
import Welcome from "./components/Welcome";
import WhyChooseUs from "./components/WhyChoose";
import Amneties from "./components/Amneties";
import Plan from "./components/Plan";
import Price from "./components/Price";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Footer from "./components/Footer";
import Faq from "./components/FAQ";
// import Location from "./components/Location";

function App() {
  const HomeSection = () => {
    return (
      <>
        <Home />
        <Form />
        <Welcome />
        <WhyChooseUs />
        <Amneties />
        <Plan />
        <Price />
        {/* <Location /> */}
        <Faq />
      </>
    );
  };

  return (
    <div className="main-wrapper">
      <Navbar />
      <Routes>
        <Route path="" exact element={HomeSection()} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
