import React from "react";
// import { useState } from "react";
import "./style.scss";
import { AMNETIES_PAGE } from "../../constants";

function Amneties() {
  const { heading, content, boxes } = AMNETIES_PAGE;

  return (
    <section class="Amenities_warpper bg-grey ptb-80" id="Amenities">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2 className="common_heading">{heading}</h2>
            <span class="divied"></span>
            {content.map((item) => {
              return <p className="common_content">{item}</p>;
            })}
          </div>
        </div>
        <div class="">
          <div class="cards_container">
            {boxes.map((item) => {
              return (
                <div class="amenity_box">
                  <div class="IconBox">
                    <img src={item.icon} alt="Swimming Pool" />
                    {/* {item.icon} */}
                  </div>
                  <h4 className="common_secondary_heading">{item.heading}</h4>
                  <p className="common_content">{item.content}</p>
                </div>
              );
            })}
          </div>
          <div class="text_left">
            <p></p>
            <p className="common_content">
              As you have read about the numerous amenities offered by 
              ELDECO La Vida Bella Sector 12, Noida Ext.
            , what are you still waiting for? Visit our
              website to view our pricing plans and schedule an appointment. You
              can also call us via the helpline.
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Amneties;
